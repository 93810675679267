.loadding-ppl{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background-color: #fff;
}
.Inloadding-ppl{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%; 
}
.Inloadding-ppl img {
    width: 45px;
    height: 45px;
    background-repeat: no-repeat;    
}
.loadding-ppl img {
    width: 60px;
    height: 60px;
    background-repeat: no-repeat;    
}
.BtnLink-iText {
    color: #fff;
    border: 0px;
    background-color: transparent;
}